var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Microsoft = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '24', height: '24', fill: 'none', viewBox: '0 0 24 24' }, { children: [_jsx("g", __assign({ clipPath: 'url(#a)' }, { children: _jsx("path", { fill: 'currentColor', d: 'M21 13v7.434a1.5 1.5 0 0 1-1.553 1.499l-.133-.011L12 21.008V13h9Zm-11 0v7.758l-5.248-.656A2 2 0 0 1 3 18.117V13h7Zm9.314-10.922a1.5 1.5 0 0 1 1.68 1.355l.006.133V11h-9V2.992l7.314-.914ZM10 3.242V11H3V5.883a2 2 0 0 1 1.752-1.985L10 3.242Z' }, void 0) }), void 0), _jsx("defs", { children: _jsx("clipPath", __assign({ id: 'a' }, { children: _jsx("path", { fill: '#fff', d: 'M0 0h24v24H0z' }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
export default Microsoft;
