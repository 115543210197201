var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from 'classnames';
import { absenceIconEnum, absenceColorCodeEnum } from '@jobdone/jobdone-shared-library';
import useStyles from './AbsenceEntry.style';
import { Caption, Text } from '../../../Typography';
import { EditAlt, MoreHorizontal, Absence, AbsenceOther, Accident, Vacation, SickDay, Education, Military, Funeral, Maternity, Trash, ArrowUpRight, } from '../../../icons';
import { AbsenceEntryTypes } from '../../commons/enums';
import { getAbbreviatedName, useDropdownMenu } from '../../../../utils/helpers';
import { EntryMenuItems, absenceMenuItems } from '../..';
import { Tooltip } from '../../../Tooltip';
import { rangePickerVariantEnum } from '../../../../utils';
import { Avatar } from '../../../Avatar';
export var AbsenceEntry = function (_a) {
    var _b;
    var testId = _a.testId, _c = _a.entryType, entryType = _c === void 0 ? 'basic' : _c, _d = _a.isPublished, isPublished = _d === void 0 ? false : _d, _e = _a.title, title = _e === void 0 ? 'Absence 1' : _e, _f = _a.time, time = _f === void 0 ? 'All Day' : _f, _g = _a.color, color = _g === void 0 ? absenceColorCodeEnum.RED : _g, className = _a.className, blockedTitle = _a.blockedTitle, _h = _a.icon, icon = _h === void 0 ? absenceIconEnum.ABSENCE : _h, onEditClick = _a.onEditClick, onDeleteClick = _a.onDeleteClick, menuItems = _a.menuItems, hasFlash = _a.hasFlash, _j = _a.showHoverActions, showHoverActions = _j === void 0 ? true : _j, selected = _a.selected, _k = _a.isFlashMode, isFlashMode = _k === void 0 ? false : _k, _l = _a.labels, labels = _l === void 0 ? {
        deleteLabel: 'Delete',
    } : _l, _m = _a.view, view = _m === void 0 ? rangePickerVariantEnum['1_WEEK'] : _m, _o = _a.isCompact, isCompact = _o === void 0 ? false : _o, assignedUserAvatar = _a.assignedUserAvatar, assignedUserFullName = _a.assignedUserFullName, isResourceView = _a.isResourceView, _p = _a.isDifferentFromTemplate, isDifferentFromTemplate = _p === void 0 ? false : _p, rest = __rest(_a, ["testId", "entryType", "isPublished", "title", "time", "color", "className", "blockedTitle", "icon", "onEditClick", "onDeleteClick", "menuItems", "hasFlash", "showHoverActions", "selected", "isFlashMode", "labels", "view", "isCompact", "assignedUserAvatar", "assignedUserFullName", "isResourceView", "isDifferentFromTemplate"]);
    var classes = useStyles({
        color: color,
        hasFlash: hasFlash,
        selected: selected,
        isFlashMode: isFlashMode,
        isBlocked: entryType === AbsenceEntryTypes.isBlocked,
    });
    var _q = useDropdownMenu(), handleCloseMore = _q.handleClose, anchorEl = _q.anchorEl, handleClickMore = _q.handleClick;
    var _r = __read(time.split(' - '), 2), absenceFromTime = _r[0], absenceToTime = _r[1];
    var absenceFromTimeRawHour = absenceFromTime.split(':')[0];
    var absenceFromTimeHour = absenceFromTimeRawHour[0] === '0' ? absenceFromTimeRawHour[1] : absenceFromTimeRawHour;
    var absenceFromTimeMinute = absenceFromTime === null || absenceFromTime === void 0 ? void 0 : absenceFromTime.split(':')[1];
    var absenceToTimeRawHour = (_b = absenceToTime === null || absenceToTime === void 0 ? void 0 : absenceToTime.split(':')) === null || _b === void 0 ? void 0 : _b[0];
    var absenceToTimeHour = (absenceToTimeRawHour === null || absenceToTimeRawHour === void 0 ? void 0 : absenceToTimeRawHour[0]) === '0' ? absenceToTimeRawHour[1] : absenceToTimeRawHour;
    var absenceToTimeMinute = absenceToTime === null || absenceToTime === void 0 ? void 0 : absenceToTime.split(':')[1];
    var combineMenuItems = function () { return (menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(function (menuItem) { return (__assign(__assign({}, absenceMenuItems.find(function (_a) {
        var key = _a.key;
        return key === menuItem.key;
    })), menuItem)); })) || []; };
    var cantRenderOverlay = function () { return (entryType === AbsenceEntryTypes.isCopied || !showHoverActions); };
    var renderOneWeekOverlay = function () {
        var _a, _b;
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsxs("div", __assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneWeekOverlay, (_a = {},
                        _a[classes.compactViewOverlayButton] = isCompact,
                        _a)), onClick: onDeleteClick }, { children: [_jsx(Trash, { className: classes.trashIcon }, void 0), _jsx(Text, __assign({ className: classes.deleteLabel }, { children: labels.deleteLabel }), void 0)] }), void 0) }, void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneWeekOverlay, (_b = {},
                        _b[classes.compactViewOverlayButton] = isCompact,
                        _b)) }, { children: [_jsx("button", __assign({ onClick: onEditClick, className: classes.overlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", __assign({ onClick: handleClickMore, className: classes.overlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    var renderTwoWeeksOverlay = function () {
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsx("div", __assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.twoWeeksOverlay), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0) }, void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.twoWeeksOverlay) }, { children: [_jsx("button", __assign({ onClick: onEditClick, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", __assign({ onClick: handleClickMore, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    var renderOneMonthOverlay = function () {
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsx("div", __assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneMonthOverlay), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0) }, void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneMonthOverlay) }, { children: [_jsx("button", __assign({ onClick: onEditClick, className: classes.oneMonthOverlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", __assign({ onClick: handleClickMore, className: classes.oneMonthOverlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    var renderAssignedUser = function () { return (_jsx(Avatar, { src: assignedUserAvatar, placeHolder: assignedUserFullName, size: 'xs', className: cx(classes.absenceIcon, classes.assignedUserAvatarBlock) }, void 0)); };
    var renderAbsenceIcon = function () {
        if (isResourceView) {
            return renderAssignedUser();
        }
        switch (icon) {
            case absenceIconEnum.ABSENCE:
                return _jsx(Absence, { "aria-label": 'absence-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.ABSENCE_OTHER:
                return _jsx(AbsenceOther, { "aria-label": 'absence-other-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.ACCIDENT:
                return _jsx(Accident, { "aria-label": 'accident-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.VACATION:
                return _jsx(Vacation, { "aria-label": 'vacation-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.SICK_DAY:
                return _jsx(SickDay, { "aria-label": 'sick-day-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.EDUCATION:
                return _jsx(Education, { "aria-label": 'education-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.MILITARY:
                return _jsx(Military, { "aria-label": 'military-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.FUNERAL:
                return _jsx(Funeral, { "aria-label": 'funeral-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.MATERNITY:
                return _jsx(Maternity, { "aria-label": 'maternity-icon', className: classes.absenceIcon }, void 0);
            default:
                return _jsx(Absence, { "aria-label": 'absence-icon', className: classes.absenceIcon }, void 0);
        }
    };
    var renderIsDifferentFromTemplate = isDifferentFromTemplate && (_jsx("div", { className: classes.differentFromTemplate }, void 0));
    var renderOneWeekContent = function () {
        var _a;
        var renderContent = function () {
            var _a;
            if (isCompact) {
                return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer, classes.weekCompactView) }, { children: [renderIsDifferentFromTemplate, _jsxs("div", __assign({ className: classes.compactViewIconAndTime }, { children: [entryType === AbsenceEntryTypes.isBlocked
                                        ? _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.compactViewBlockedIcon }, void 0)
                                        : renderAbsenceIcon(), _jsx("div", __assign({ className: classes.compactTimeContainer }, { children: !absenceToTime
                                            ? (_jsx(Caption, __assign({ variant: 'regular', className: classes.compactViewAbsenceName }, { children: getAbbreviatedName(absenceFromTime) }), void 0))
                                            : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: absenceFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: absenceFromTimeMinute }), void 0)] }), void 0), "-", _jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: absenceToTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: absenceToTimeMinute }), void 0)] }), void 0)] }, void 0)) }), void 0)] }), void 0), _jsx(Caption, __assign({ className: cx(classes.absenceNameBlock, classes.compactViewAbsenceName, (_a = {},
                                    _a[classes.marginRight] = isDifferentFromTemplate,
                                    _a)), weight: 500 }, { children: title }), void 0)] }), void 0) }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer) }, { children: [renderAbsenceIcon(), renderIsDifferentFromTemplate, _jsx(Caption, __assign({ variant: 'regular' }, { children: time }), void 0)] }), void 0), _jsxs("div", __assign({ className: cx(classes.bottomContainer, classes.oneWeekBottomContainer) }, { children: [_jsx(Caption, __assign({ className: classes.absenceNameBlock, variant: 'regular' }, { children: title }), void 0), entryType === AbsenceEntryTypes.isBlocked && _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.blockedIcon }, void 0)] }), void 0)] }, void 0));
        };
        return (_jsx("div", __assign({}, rest, { "data-testid": testId, className: cx(classes.container, classes.oneWeekContainer, (_a = {},
                _a[classes.isHover] = !cantRenderOverlay() && showHoverActions,
                _a[classes.isBlocked] = entryType === AbsenceEntryTypes.isBlocked,
                _a["" + String(className)] = !!className,
                _a[classes.flash] = hasFlash,
                _a[classes.selected] = selected,
                _a[classes.isFlashMode] = isFlashMode,
                _a)) }, { children: selected
                ? (_jsxs("div", __assign({ className: classes.selectedContainer }, { children: [!isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderContent(), renderOverlay()] }), void 0))
                : (_jsxs(_Fragment, { children: [!isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderContent(), renderOverlay()] }, void 0)) }), void 0));
    };
    var renderTwoWeeksContent = function () {
        var _a, _b;
        return (_jsxs("div", __assign({}, rest, { "data-testid": testId, className: cx(classes.container, classes.twoWeeksContainer, (_a = {},
                _a[classes.isHover] = !cantRenderOverlay() && showHoverActions,
                _a[classes.isBlocked] = entryType === AbsenceEntryTypes.isBlocked,
                _a["" + String(className)] = !!className,
                _a[classes.flash] = hasFlash,
                _a[classes.selected] = selected,
                _a[classes.isFlashMode] = isFlashMode,
                _a)) }, { children: [!isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderOverlay(), _jsxs("div", __assign({ className: cx(classes.topContainer, classes.twoWeeksTopContainer) }, { children: [renderIsDifferentFromTemplate, entryType === AbsenceEntryTypes.isBlocked
                            ? _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.absenceIcon }, void 0)
                            : renderAbsenceIcon(), _jsx(Caption, __assign({ className: cx(classes.absenceNameBlock, classes.twoWeekViewAbsenceNameBlock, (_b = {},
                                _b[classes.marginRight] = isDifferentFromTemplate,
                                _b)), variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0)] }), void 0), _jsx("div", __assign({ className: cx(classes.bottomContainer, classes.twoWeeksBottomContainer) }, { children: _jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: classes.twoWeeksTimeContainer }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: absenceFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.twoWeeksTimeMinute, size: 8, variant: 'regular' }, { children: absenceFromTimeMinute }), void 0)] }), void 0), absenceToTimeMinute && (_jsxs(_Fragment, { children: ["-", _jsxs("div", __assign({ className: classes.twoWeeksTimeContainer }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: absenceToTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.twoWeeksTimeMinute, size: 8, variant: 'regular' }, { children: absenceToTimeMinute }), void 0)] }), void 0)] }, void 0))] }, void 0) }), void 0)] }), void 0));
    };
    var renderOneMonthContent = function () {
        var _a;
        var renderContent = function () {
            var _a;
            if (isCompact) {
                return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, classes.oneMonthCompactTopContainer) }, { children: _jsx("div", __assign({ className: cx(classes.compactTimeContainer, classes.compactTimeContainerOneMonth) }, { children: _jsx("div", __assign({ className: classes.compactTimeEntry }, { children: !absenceFromTimeMinute
                                        ? (_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: getAbbreviatedName(absenceFromTimeHour) }), void 0))
                                        : (_jsxs(_Fragment, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: absenceFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: absenceFromTimeMinute }), void 0)] }, void 0)) }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer, classes.oneMonthCompactBottomContainer) }, { children: _jsx(Caption, __assign({ className: cx(classes.absenceNameBlock, classes.oneMonthAbsenceNameBlock), variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0) }), void 0), entryType === AbsenceEntryTypes.isBlocked && (_jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.oneMonthBlockedIcon }, void 0))] }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, (_a = {},
                            _a[classes.paddingRight] = isDifferentFromTemplate,
                            _a)) }, { children: entryType === AbsenceEntryTypes.isBlocked
                            ? _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.absenceIcon }, void 0)
                            : renderAbsenceIcon() }), void 0), _jsx("div", __assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer) }, { children: _jsx(Caption, __assign({ className: classes.absenceNameBlock, variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0) }), void 0)] }, void 0));
        };
        return (_jsxs("div", __assign({}, rest, { "data-testid": testId, className: cx(classes.container, classes.oneMonthContainer, (_a = {},
                _a[classes.isHover] = !cantRenderOverlay() && showHoverActions,
                _a[classes.isBlocked] = entryType === AbsenceEntryTypes.isBlocked,
                _a["" + String(className)] = !!className,
                _a[classes.flash] = hasFlash,
                _a[classes.selected] = selected,
                _a[classes.isFlashMode] = isFlashMode,
                _a)) }, { children: [!isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderIsDifferentFromTemplate, renderOverlay(), renderContent()] }), void 0));
    };
    var renderOverlay = function () {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekOverlay();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksOverlay();
            case rangePickerVariantEnum['1_MONTH']:
                return renderOneMonthOverlay();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    var renderContent = function () {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekContent();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksContent();
            case rangePickerVariantEnum['1_MONTH']:
                return renderOneMonthContent();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    if (entryType === AbsenceEntryTypes.isBlocked && blockedTitle) {
        return (_jsx(Tooltip, __assign({ title: blockedTitle }, { children: renderContent() }), void 0));
    }
    return renderContent();
};
