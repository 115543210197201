import { makeStyles, alpha } from '@material-ui/core';
var useStyles = makeStyles(function (theme) { return ({
    container: function (_a) {
        var _b = _a.color, color = _b === void 0 ? '' : _b, selected = _a.selected, isBlocked = _a.isBlocked;
        return ({
            position: 'relative',
            overflow: 'hidden',
            borderRadius: selected ? 12 : 8,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            userSelect: 'none',
            border: (selected ? 2 : 1) + "px solid " + (isBlocked ? theme.palette.grey[300] : color),
            marginBottom: 1,
        });
    },
    isHover: function (_a) {
        var _b = _a.color, color = _b === void 0 ? '' : _b;
        return ({
            '&:hover': {
                transition: theme.transitions.create('border'),
                border: "1px dashed " + color,
            },
            '&:hover $overlay': {
                opacity: '1',
                transition: theme.transitions.create('opacity'),
            },
        });
    },
    overlay: function (_a) {
        var _b = _a.color, color = _b === void 0 ? '' : _b;
        return ({
            boxSizing: 'border-box',
            opacity: '0',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            background: alpha(color, 0.9),
            color: theme.palette.common.white,
        });
    },
    oneWeekOverlay: {
        display: 'flex',
        justifyContent: 'center',
        gap: '8px',
        alignItems: 'center',
    },
    twoWeeksOverlay: {
        zIndex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    oneMonthOverlay: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlayButton: {
        border: "2px solid " + theme.palette.common.white,
        borderRadius: '8px',
        background: 'transparent',
        color: theme.palette.common.white,
        cursor: 'pointer',
        lineHeight: '0 !important',
        transition: theme.transitions.create(['background', 'color']),
        '&:hover': {
            background: alpha(theme.palette.common.white, 0.8),
            borderColor: 'transparent',
            color: function (_a) {
                var _b = _a.color, color = _b === void 0 ? '' : _b;
                return (alpha(color, 0.9));
            },
        },
        '&:active': {
            background: theme.palette.common.white,
            color: function (_a) {
                var _b = _a.color, color = _b === void 0 ? '' : _b;
                return (alpha(color, 0.9));
            },
        },
    },
    twoWeeksOverlayButton: {
        padding: '1px 2px',
        lineHeight: '0 !important',
        '& svg': {
            width: '20px !important',
            height: '20px !important',
        },
    },
    oneMonthOverlayButton: {
        background: 'transparent',
        color: theme.palette.common.white,
        cursor: 'pointer',
        border: 'none',
        lineHeight: '0 !important',
        '& svg': {
            width: '18px !important',
            height: '18px !important',
        },
    },
    topContainer: function (_a) {
        var _b = _a.color, color = _b === void 0 ? '' : _b, _c = _a.hasFlash, hasFlash = _c === void 0 ? false : _c, _d = _a.selected, selected = _d === void 0 ? false : _d, _e = _a.isFlashMode, isFlashMode = _e === void 0 ? false : _e;
        return ({
            background: alpha(color, 0.1),
            display: 'flex',
            borderTopLeftRadius: hasFlash || selected || isFlashMode ? 4 : 0,
            borderTopRightRadius: hasFlash || selected || isFlashMode ? 4 : 0,
        });
    },
    oneWeekTopContainer: {
        paddingRight: 2,
        height: 20,
        gap: '4px',
        alignItems: 'center',
    },
    twoWeeksTopContainer: {
        paddingRight: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& $eventIcon': {
            textAlign: 'left',
        },
    },
    twoWeeksTimeMinute: {
        transform: 'translateY(-2px)',
    },
    twoWeeksTimeContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    weekCompactView: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
    },
    compactViewIconAndTime: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        width: '100%',
    },
    compactTimeMinute: {
        transform: 'translateY(-2px)',
    },
    compactViewOverlayButton: {
        justifyContent: 'center',
        gap: 8,
        '& button': {
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
                width: 18,
                height: 18,
            },
        },
    },
    compactViewEventName: function (_a) {
        var _b = _a.color, color = _b === void 0 ? '' : _b;
        return ({
            color: color,
            marginRight: 4,
            textAlign: 'right',
            fontWeight: 500,
            fontSize: '0.8rem',
        });
    },
    oneMonthTopContainer: {
        justifyContent: 'center',
    },
    oneMonthBlockedIcon: function (_a) {
        var _b = _a.color, color = _b === void 0 ? '' : _b;
        return ({
            position: 'absolute',
            content: '""',
            bottom: 0,
            right: 0,
            color: color,
            backgroundColor: theme.palette.grey[300],
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            borderTopRightRadius: 4,
        });
    },
    oneMonthCompactTopContainer: {
        justifyContent: 'left',
        paddingLeft: 2,
    },
    compactTimeContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    compactTimeEntry: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    compactTimeContainerOneMonth: {
        paddingTop: 4,
        textAlign: 'left',
    },
    bottomContainer: function (_a) {
        var _b = _a.color, color = _b === void 0 ? '' : _b, _c = _a.hasFlash, hasFlash = _c === void 0 ? false : _c, _d = _a.selected, selected = _d === void 0 ? false : _d, _e = _a.isFlashMode, isFlashMode = _e === void 0 ? false : _e;
        return ({
            height: 20,
            background: alpha(color, 0.1),
            display: 'flex',
            alignItems: 'center',
            '& > *': {
                color: color,
            },
            borderBottomLeftRadius: hasFlash || selected || isFlashMode ? 4 : 0,
            borderBottomRightRadius: hasFlash || selected || isFlashMode ? 4 : 0,
        });
    },
    oneWeekBottomContainer: {
        padding: '0px 8px',
        justifyContent: 'space-between',
    },
    oneMonthBottomContainer: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    oneMonthCompactBottomContainer: {
        textAlign: 'left',
    },
    twoWeeksBottomContainer: {
        padding: '0px 4px',
        justifyContent: 'flex-start',
        '& > span': {
            color: theme.palette.common.black,
        },
    },
    twoWeekViewEventNameBlock: function (_a) {
        var color = _a.color;
        return ({
            color: color,
            textAlign: 'right',
            marginRight: 2,
        });
    },
    eventNameBlock: {
        width: '80%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    oneMonthEventNameBlock: {
        textTransform: 'uppercase',
    },
    eventIcon: function (_a) {
        var color = _a.color;
        return ({
            '& > svg': {
                width: 20,
                height: 20,
            },
            color: color,
        });
    },
    flash: {
        '--angle': '0deg',
        border: '4px solid transparent !important',
        boxSizing: 'border-box',
        backgroundOrigin: 'border-box',
        backgroundImage: 'conic-gradient(from var(--angle), #3C5AF9 0%, #F96955 4.69%, #FD87AA 8.85%, #FCD5AD 16.15%, #E97584 23.96%, #FED556 31.77%, #FED655 40.63%, #FFF4F2 48.44%, #F2CDFE 54.69%, #FD99F9 63.54%,  #D693FA 69.79%, #E492E4 77.08%, #1E9AFA 83.85%, #8CFBD7 90.63%, #2E50F6 100%)',
        boxShadow: "2px 1000px 1px " + theme.palette.common.white + " inset",
        animation: '$rotate 2s linear infinite',
    },
    isFlashMode: {
        '& $overlay': {
            background: 'rgba(224, 0, 11, 0.85)',
            margin: 2,
            borderRadius: 4,
            width: 'calc(100% - 4px)',
            height: 'calc(100% - 4px)',
            gap: '2px',
            cursor: 'pointer',
        },
        '&:hover': {
            '--angle': '0deg',
            border: '4px solid transparent !important',
            boxSizing: 'border-box',
            backgroundOrigin: 'border-box',
            backgroundImage: 'conic-gradient(from var(--angle), #3C5AF9 0%, #F96955 4.69%, #FD87AA 8.85%, #FCD5AD 16.15%, #E97584 23.96%, #FED556 31.77%, #FED655 40.63%, #FFF4F2 48.44%, #F2CDFE 54.69%, #FD99F9 63.54%,  #D693FA 69.79%, #E492E4 77.08%, #1E9AFA 83.85%, #8CFBD7 90.63%, #2E50F6 100%)',
            boxShadow: "2px 1000px 1px " + theme.palette.common.white + " inset",
            animation: '$rotate 2s linear infinite',
        },
    },
    '@keyframes rotate': {
        to: {
            '--angle': '360deg',
        },
    },
    trashIcon: {
        height: 18,
        '& svg': {
            height: 18,
        },
    },
    deleteLabel: {
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '16px',
    },
    selected: {
        boxSizing: 'border-box',
    },
    selectedContainer: function (_a) {
        var _b = _a.color, color = _b === void 0 ? '' : _b;
        return ({
            margin: 2,
            boxSizing: 'border-box',
            borderRadius: 8,
            border: "1px solid " + color,
        });
    },
    isBlocked: {
        '& $topContainer': {
            background: alpha(theme.palette.grey[500], 0.1),
        },
        '& $bottomContainer': {
            background: alpha(theme.palette.grey[500], 0.1),
        },
    },
    compactViewBlockedIcon: {
        '& svg': {
            width: 20,
            height: 20,
            color: function (_a) {
                var _b = _a.color, color = _b === void 0 ? '' : _b;
                return color;
            },
        },
    },
}); });
export default useStyles;
