var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var Book = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M4 5C4 3.34315 5.34315 2 7 2H18C19.1046 2 20 2.89543 20 4V16.99C20 17.1576 19.9623 17.3115 19.8872 17.4617L19.3416 18.5528C19.2009 18.8343 19.2009 19.1657 19.3416 19.4472L19.8854 20.5348C19.9586 20.6737 20 20.832 20 21C20 21.5523 19.5523 22 19 22H7C5.34315 22 4 20.6569 4 19V5ZM7 18H17.4076C17.1789 18.6468 17.1789 19.3532 17.4076 20H7C6.44772 20 6 19.5523 6 19C6 18.4477 6.44772 18 7 18ZM10 7C9.44772 7 9 7.44772 9 8C9 8.55228 9.44772 9 10 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H10Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M8 8L16 8', stroke: 'white', strokeWidth: '2', strokeLinecap: 'round' }, void 0)] }), void 0) }), void 0));
};
export default Book;
