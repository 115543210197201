var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { az, de, it, fr, enGB, ru, } from 'date-fns/locale';
import moment from 'moment';
export var useCombinedRefs = function (innerRef, forwardRef) {
    var targetRef = React.useRef(null);
    React.useEffect(function () {
        var refs = [innerRef, forwardRef];
        refs.forEach(function (ref) {
            var currentRef = ref;
            if (!currentRef)
                return;
            if (typeof currentRef === 'function') {
                currentRef(targetRef.current);
            }
            else {
                // Type assertion to MutableRefObject to allow assignment
                // eslint-disable-next-line no-param-reassign
                ref.current = targetRef.current;
            }
        });
    }, [forwardRef, innerRef]);
    return targetRef;
};
export var getYear = function (date) { return date.getFullYear(); };
export var getMonth = function (date) { return date.getMonth(); };
export var getDay = function (date) { return date.getDate(); };
export var getMondayOfWeek = function (date) { return new Date(date.setDate(date.getDate() - (date.getDay() + 6) % 7)); };
export var getSundayOfWeek = function (date) { return new Date(date.setDate(date.getDate() - (date.getDay() - 7) % 7)); };
export var getSundayOfNextWeek = function (date) { return new Date(date.setDate(date.getDate() - (date.getDay() - 14) % 14)); };
export var getEndOfMonth = function (date) { return new Date(moment(date).endOf('month').toString()); };
export var getStartOfMonth = function (date) { return new Date(moment(date).startOf('month').toString()); };
export var getEndOfDay = function (date) { return new Date(moment(date).endOf('day').toString()); };
export var getStartOfDay = function (date) { return new Date(moment(date).startOf('day').toString()); };
export var getStartOfYear = function (date) { return new Date(moment(date).startOf('year').toString()); };
export var getEndOfYear = function (date) { return new Date(moment(date).endOf('year').toString()); };
export var getMaxDate = function (date) { return new Date(moment(date).add(31, 'days').toString()); };
export var getAbbreviatedName = function (name) { return name.split(' ').map(function (n) { return n[0]; }).join(''); };
export var JobDoneLocales;
(function (JobDoneLocales) {
    JobDoneLocales["GERMAN"] = "de-DE";
    JobDoneLocales["SWISS_GERMAN"] = "de-CH";
    JobDoneLocales["GREAT_BRITAIN_ENGLISH"] = "en-GB";
    JobDoneLocales["ENGLISH"] = "en-US";
    JobDoneLocales["AZERBAIJANI"] = "az";
    JobDoneLocales["ITALIAN"] = "it-IT";
    JobDoneLocales["FRENCH"] = "fr-FR";
    JobDoneLocales["RUSSIAN"] = "ru";
})(JobDoneLocales || (JobDoneLocales = {}));
export var getDateFnsLocaleFromJobDoneLocale = function (jobdoneLocale) {
    switch (jobdoneLocale) {
        case JobDoneLocales.GERMAN:
            return de;
        case JobDoneLocales.SWISS_GERMAN:
            return de;
        case JobDoneLocales.AZERBAIJANI:
            return az;
        case JobDoneLocales.ITALIAN:
            return it;
        case JobDoneLocales.FRENCH:
            return fr;
        case JobDoneLocales.ENGLISH:
            return enGB;
        case JobDoneLocales.RUSSIAN:
            return ru;
        default:
            return enGB; // dateDns requires another format for en --> we use enGB
    }
};
export var useDropdownMenu = function () {
    var _a = __read(React.useState(null), 2), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return { handleClick: handleClick, handleClose: handleClose, anchorEl: anchorEl };
};
export var getMonthNames = function (locale) {
    var monthArray = [];
    var objDate = new Date();
    objDate.setDate(1);
    for (var i = 0; i < 12; i += 1) {
        objDate.setMonth(i);
        var month = objDate.toLocaleString(locale, { month: 'long' });
        monthArray.push(month);
    }
    return monthArray;
};
export var createArrayWithKeysAsValue = function (count) { return Array.from(Array(count).keys()); };
