var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect } from 'react';
import cx from 'classnames';
import { shiftColorCodeEnum, shiftIconEnum } from '@jobdone/jobdone-shared-library';
import useStyles from './ShiftEntry.styles';
import { Body, Caption, Text } from '../../../Typography';
import { Briefcase, EditAlt, MoreHorizontal, ArrowUpRight, Car, Headphones, Home, Moon, PhoneAlt, Star, Sun, SunHaze, Users, Trash, ShiftBlocked, } from '../../../icons';
import { Tooltip } from '../../../Tooltip';
import { ShiftEntryTypes } from '../../commons/enums';
import { getAbbreviatedName, useDropdownMenu } from '../../../../utils/helpers';
import { EntryMenuItems, shiftMenuItems } from '../..';
import { Skeleton } from '../../../Skeleton';
import { ConfirmationStatusEnum, rangePickerVariantEnum } from '../../../../utils';
import { Icons } from '../../../..';
export var ShiftEntry = function (_a) {
    var _b;
    var _c;
    var testId = _a.testId, _d = _a.entryType, entryType = _d === void 0 ? 'basic' : _d, _e = _a.isPublished, isPublished = _e === void 0 ? false : _e, _f = _a.title, title = _f === void 0 ? 'Shift 1' : _f, _g = _a.time, time = _g === void 0 ? '7:00 - 11:00' : _g, timeNote = _a.timeNote, _h = _a.color, color = _h === void 0 ? shiftColorCodeEnum.BLUE : _h, className = _a.className, _j = _a.icon, icon = _j === void 0 ? shiftIconEnum.BRIEFCASE : _j, blockedTitle = _a.blockedTitle, onEditClick = _a.onEditClick, onDeleteClick = _a.onDeleteClick, menuItems = _a.menuItems, _k = _a.isOpenShift, isOpenShift = _k === void 0 ? false : _k, _l = _a.showHoverActions, showHoverActions = _l === void 0 ? true : _l, hasFlash = _a.hasFlash, selected = _a.selected, _m = _a.isFlashMode, isFlashMode = _m === void 0 ? false : _m, _o = _a.labels, labels = _o === void 0 ? {
        deleteLabel: 'Delete',
    } : _o, loading = _a.loading, _p = _a.view, view = _p === void 0 ? rangePickerVariantEnum['1_WEEK'] : _p, _q = _a.isCompact, isCompact = _q === void 0 ? false : _q, _r = _a.isCompactWithBreak, isCompactWithBreak = _r === void 0 ? false : _r, assignedUserAvatar = _a.assignedUserAvatar, assignedUserFullName = _a.assignedUserFullName, isResourceView = _a.isResourceView, isConfirmationMode = _a.isConfirmationMode, confirmationStatus = _a.confirmationStatus, breakDurationInMinuteLocale = _a.breakDurationInMinuteLocale, breaks = _a.breaks, firstName = _a.firstName, lastName = _a.lastName, _s = _a.isDifferentFromTemplate, isDifferentFromTemplate = _s === void 0 ? false : _s, rest = __rest(_a, ["testId", "entryType", "isPublished", "title", "time", "timeNote", "color", "className", "icon", "blockedTitle", "onEditClick", "onDeleteClick", "menuItems", "isOpenShift", "showHoverActions", "hasFlash", "selected", "isFlashMode", "labels", "loading", "view", "isCompact", "isCompactWithBreak", "assignedUserAvatar", "assignedUserFullName", "isResourceView", "isConfirmationMode", "confirmationStatus", "breakDurationInMinuteLocale", "breaks", "firstName", "lastName", "isDifferentFromTemplate"]);
    var classes = useStyles({
        color: color,
        hasFlash: hasFlash,
        selected: selected,
        isFlashMode: isFlashMode,
        isBlocked: entryType === ShiftEntryTypes.isBlocked,
        breaksLength: breaks === null || breaks === void 0 ? void 0 : breaks.length,
    });
    var _t = useDropdownMenu(), handleCloseMore = _t.handleClose, anchorEl = _t.anchorEl, handleClickMore = _t.handleClick;
    var _u = __read(time.split(' - '), 2), shiftFromTime = _u[0], shiftToTime = _u[1];
    var shiftFromTimeRawHour = shiftFromTime.split(':')[0];
    var shiftFromTimeHour = shiftFromTimeRawHour[0] === '0' ? shiftFromTimeRawHour[1] : shiftFromTimeRawHour;
    var shiftFromTimeMinute = shiftFromTime.split(':')[1];
    var shiftToTimeRawHour = (_c = shiftToTime.split(':')) === null || _c === void 0 ? void 0 : _c[0];
    var shiftToTimeHour = shiftToTimeRawHour[0] === '0' ? shiftToTimeRawHour[1] : shiftToTimeRawHour;
    var shiftToTimeMinute = shiftToTime.split(':')[1];
    var shiftBreaks = breaks === null || breaks === void 0 ? void 0 : breaks.map(function (_breakTime) {
        var output = { duration: _breakTime.duration ? Number(_breakTime === null || _breakTime === void 0 ? void 0 : _breakTime.duration) / 60 : 0 };
        if (_breakTime.from) {
            var fromDate = new Date(_breakTime.from);
            output = __assign(__assign({}, output), { fromHour: fromDate.getHours().toString(), fromMinute: fromDate.getMinutes().toString().padStart(2, '0') });
        }
        if (_breakTime.to) {
            var toDate = new Date(_breakTime.to);
            output = __assign(__assign({}, output), { toHour: toDate.getHours().toString(), toMinute: toDate.getMinutes().toString().padStart(2, '0') });
        }
        return output;
    });
    useEffect(function () {
        try {
            // TS Bug: https://github.com/microsoft/TypeScript/issues/38593
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var CSS_1 = window.CSS;
            CSS_1.registerProperty({
                name: '--angle',
                initialValue: '0deg',
                syntax: '<angle>',
                inherits: false,
            });
        }
        catch (err) {
            console.log(err);
        }
    }, []);
    if (loading) {
        return (_jsx("div", __assign({ className: classes.container }, { children: _jsx(Skeleton, { animation: false, variant: 'rect', className: cx(classes.skeletonBlock, (_b = {},
                    _b[classes.oneWeekSkeletonBlock] = view === rangePickerVariantEnum['1_WEEK'],
                    _b[classes.twoWeekSkeletonBlock] = view === rangePickerVariantEnum['2_WEEK'],
                    _b[classes.oneMonthSkeletonBlock] = view === rangePickerVariantEnum['1_MONTH'],
                    _b)) }, void 0) }), void 0));
    }
    var renderIsDifferentFromTemplate = isDifferentFromTemplate && (_jsx("div", { className: classes.differentFromTemplate }, void 0));
    var renderConfirmationIcon = function () {
        if (isConfirmationMode && confirmationStatus) {
            switch (confirmationStatus) {
                case ConfirmationStatusEnum.rejected:
                    return (_jsx("div", __assign({ className: cx(classes.confirmationModeBlock, classes.confirmationRejected) }, { children: _jsx(Icons.Violated, {}, void 0) }), void 0));
                case ConfirmationStatusEnum.open:
                    return (_jsx("div", __assign({ className: classes.confirmationModeBlock }, { children: _jsx(Icons.AwaitingApprovalCircle, {}, void 0) }), void 0));
                case ConfirmationStatusEnum.approved:
                    return (_jsx("div", __assign({ className: classes.confirmationModeBlock }, { children: _jsx(Icons.ApprovedCircleCheck, {}, void 0) }), void 0));
                default:
                    return _jsx(_Fragment, {}, void 0);
            }
        }
        return _jsx(_Fragment, {}, void 0);
    };
    var combineMenuItems = function () { return (menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(function (menuItem) { return (__assign(__assign({}, shiftMenuItems.find(function (_a) {
        var key = _a.key;
        return key === menuItem.key;
    })), menuItem)); })) || []; };
    var cantRenderOverlay = function () { return entryType === ShiftEntryTypes.isCopied || !showHoverActions; };
    var renderOneWeekOverlay = function () {
        var _a, _b;
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsxs("div", __assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneWeekOverlay, (_a = {},
                        _a[classes.compactViewOverlayButton] = isCompact,
                        _a)), onClick: onDeleteClick }, { children: [_jsx(Trash, { className: classes.trashIcon }, void 0), _jsx(Text, __assign({ className: classes.deleteLabel }, { children: labels.deleteLabel }), void 0)] }), void 0) }, void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneWeekOverlay, (_b = {},
                        _b[classes.compactViewOverlayButton] = isCompact,
                        _b)) }, { children: [_jsx("button", __assign({ onClick: onEditClick, className: classes.overlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", __assign({ onClick: handleClickMore, className: classes.overlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    var renderTwoWeeksOverlay = function () {
        var _a, _b;
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx("div", __assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.twoWeeksOverlay, (_a = {},
                    _a[classes.compactViewOverlayButton] = isCompact,
                    _a)), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.twoWeeksOverlay, (_b = {},
                        _b[classes.compactViewOverlayButton] = isCompact,
                        _b)) }, { children: [_jsx("button", __assign({ onClick: onEditClick, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", __assign({ onClick: handleClickMore, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    var renderMonthOverlay = function () {
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx("div", __assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneMonthOverlay), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneMonthOverlay) }, { children: [_jsx("button", __assign({ onClick: onEditClick, className: classes.oneMonthOverlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", __assign({ onClick: handleClickMore, className: classes.oneMonthOverlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    var renderOneWeekContent = function () {
        var _a;
        var renderContent = function () {
            var _a, _b;
            if (isCompactWithBreak) {
                return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer, classes.weekCompactView) }, { children: [renderConfirmationIcon(), renderIsDifferentFromTemplate, _jsxs("div", __assign({ className: classes.compactViewIconAndTime }, { children: [entryType === ShiftEntryTypes.isBlocked
                                            ? _jsx(ShiftBlocked, { "aria-label": 'blocked-icon', className: classes.compactViewBlockedIcon }, void 0)
                                            : renderShiftIcon(), _jsxs("div", __assign({ className: classes.compactTimeContainer }, { children: [timeNote && (_jsx(Caption, __assign({ variant: 'regular', className: classes.compactViewWithBreaksTimeNote }, { children: timeNote }), void 0)), _jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0), "-", _jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: shiftToTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftToTimeMinute }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsx(Caption, __assign({ className: cx(classes.shiftNameBlock, classes.compactViewShiftName, (_a = {},
                                        _a[classes.marginRight] = isDifferentFromTemplate,
                                        _a)), weight: 500 }, { children: title }), void 0)] }), void 0), _jsxs("div", __assign({ className: classes.shiftWithBreakOneWeekContainer }, { children: [_jsx("div", { className: classes.verticalLine }, void 0), _jsx("div", { children: shiftBreaks === null || shiftBreaks === void 0 ? void 0 : shiftBreaks.map(function (_a) {
                                        var fromHour = _a.fromHour, duration = _a.duration, fromMinute = _a.fromMinute, toHour = _a.toHour, toMinute = _a.toMinute;
                                        if (fromHour && duration && fromMinute && toHour && toMinute) {
                                            return (_jsxs("div", __assign({ className: classes.compactTimeContainer }, { children: [_jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ className: classes.grey700, size: 12, variant: 'regular' }, { children: fromHour }), void 0), _jsx(Caption, __assign({ className: cx(classes.compactTimeMinute, classes.grey700), size: 8, variant: 'regular' }, { children: fromMinute }), void 0)] }), void 0), "-", _jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ className: classes.grey700, size: 12, variant: 'regular' }, { children: toHour }), void 0), _jsx(Caption, __assign({ className: cx(classes.compactTimeMinute, classes.grey700), size: 8, variant: 'regular' }, { children: toMinute }), void 0)] }), void 0)] }), void 0));
                                        }
                                        return (_jsxs("div", { children: [_jsx(Caption, __assign({ className: classes.grey700, size: 12, variant: 'regular' }, { children: duration }), void 0), _jsx(Caption, __assign({ className: cx(classes.compactTimeMinute, classes.grey700, classes.breakDurationInMinute), size: 8, variant: 'regular' }, { children: breakDurationInMinuteLocale }), void 0)] }, void 0));
                                    }) }, void 0)] }), void 0)] }, void 0));
            }
            if (isCompact) {
                return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer, classes.weekCompactView) }, { children: [renderConfirmationIcon(), renderIsDifferentFromTemplate, _jsxs("div", __assign({ className: classes.compactViewIconAndTime }, { children: [entryType === ShiftEntryTypes.isBlocked
                                            ? _jsx(ShiftBlocked, { "aria-label": 'blocked-icon', className: classes.compactViewBlockedIcon }, void 0)
                                            : renderShiftIcon(), _jsxs("div", __assign({ className: classes.compactTimeContainer }, { children: [_jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0), "-", _jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: shiftToTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftToTimeMinute }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsx(Caption, __assign({ className: cx(classes.shiftNameBlock, classes.compactViewShiftName, (_b = {},
                                        _b[classes.marginRight] = isDifferentFromTemplate,
                                        _b)), weight: 500 }, { children: isResourceView ? getAbbreviatedName(title) : title }), void 0)] }), void 0), isResourceView && (_jsx("div", __assign({ className: classes.bottomContainer }, { children: _jsx(Body, __assign({ className: classes.oneWeekBottomResourceView, size: 12 }, { children: assignedUserFullName }), void 0) }), void 0))] }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer) }, { children: [renderConfirmationIcon(), renderShiftIcon(), _jsxs(Caption, __assign({ variant: 'regular' }, { children: [timeNote || time, timeNote && _jsx("span", __assign({ className: classes.timeNote }, { children: " " + time }), void 0)] }), void 0), renderIsDifferentFromTemplate] }), void 0), _jsxs("div", __assign({ className: cx(classes.bottomContainer, classes.oneWeekBottomContainer) }, { children: [_jsx(Caption, __assign({ className: classes.shiftNameBlock, variant: 'regular' }, { children: title }), void 0), entryType === ShiftEntryTypes.isBlocked && _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.blockedIcon }, void 0)] }), void 0)] }, void 0));
        };
        return (_jsxs("div", __assign({}, rest, { "data-testid": testId, className: cx(classes.container, (_a = {},
                _a[classes.isHover] = !cantRenderOverlay() && showHoverActions,
                _a[classes.isBlocked] = entryType === ShiftEntryTypes.isBlocked,
                _a["" + String(className)] = !!className,
                _a[classes.flash] = hasFlash,
                _a[classes.selected] = selected,
                _a[classes.isFlashMode] = isFlashMode,
                _a[classes.oneWeekCompactResourceView] = isResourceView && isCompact,
                _a)) }, { children: [!isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderOverlay(), renderContent()] }), void 0));
    };
    var renderTwoWeeksContent = function () {
        var _a, _b;
        return (_jsxs("div", __assign({}, rest, { "data-testid": testId, className: cx(classes.container, classes.twoWeeksContainer, (_a = {},
                _a[classes.isHover] = !cantRenderOverlay() && showHoverActions,
                _a[classes.isBlocked] = entryType === ShiftEntryTypes.isBlocked,
                _a["" + String(className)] = !!className,
                _a[classes.flash] = hasFlash,
                _a[classes.selected] = selected,
                _a[classes.isFlashMode] = isFlashMode,
                _a[classes.twoWeekCompactResourceView] = isResourceView && isCompact,
                _a)) }, { children: [renderConfirmationIcon(), renderIsDifferentFromTemplate, !isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderOverlay(), _jsxs("div", __assign({ className: cx(classes.topContainer, classes.twoWeeksTopContainer) }, { children: [entryType === ShiftEntryTypes.isBlocked
                            ? _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.shiftIcon }, void 0)
                            : renderShiftIcon(), _jsx(Caption, __assign({ className: cx(classes.shiftNameBlock, classes.twoWeekViewShiftNameBlock, (_b = {},
                                _b[classes.marginRight] = isDifferentFromTemplate,
                                _b)), variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0)] }), void 0), isResourceView ? (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: cx(classes.twoWeeksResourceViewBottomContainer, classes.twoWeeksBottomContainer) }, { children: [_jsxs("div", __assign({ className: 'flex items-center text-common-black' }, { children: [_jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0), _jsx("span", __assign({ className: 'text-common-black !m-0 !p-0', style: { lineHeight: 0 } }, { children: "-" }), void 0), ' ', _jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: shiftToTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftToTimeMinute }), void 0)] }), void 0)] }), void 0), isResourceView && (_jsx(Body, __assign({ className: classes.twoWeekBottomResourceView, size: 12 }, { children: firstName && lastName
                                    ? String(firstName) + " " + String(lastName === null || lastName === void 0 ? void 0 : lastName[0]) + "."
                                    : '-' }), void 0))] }), void 0) }, void 0)) : (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: cx(classes.bottomContainer, classes.twoWeeksBottomContainer) }, { children: [_jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0), _jsx("span", __assign({ className: 'text-common-black !m-0 !p-0', style: { lineHeight: 0 } }, { children: "-" }), void 0), ' ', _jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: shiftToTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftToTimeMinute }), void 0)] }), void 0)] }), void 0) }, void 0))] }), void 0));
    };
    var renderOneMonthContent = function () {
        var _a;
        var renderContent = function () {
            var _a;
            if (isCompact) {
                if (isResourceView) {
                    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, classes.oneMonthCompactTopContainer) }, { children: [renderConfirmationIcon(), _jsx("div", __assign({ className: cx(classes.compactTimeContainer, classes.compactTimeContainerOneMonth) }, { children: _jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: cx(classes.oneMonthResourceViewBottomContainer, classes.oneMonthBottomContainer, classes.oneMonthCompactBottomContainer) }, { children: [_jsx(Caption, __assign({ className: cx(classes.shiftNameBlock, classes.oneMonthShiftNameBlock), variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0), _jsx(Caption, __assign({ className: cx(classes.shiftNameBlock, classes.oneMonthShiftNameBlock), variant: 'regular' }, { children: getAbbreviatedName(String(assignedUserFullName)) }), void 0)] }), void 0), entryType === ShiftEntryTypes.isBlocked && (_jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.oneMonthBlockedIcon }, void 0))] }, void 0));
                }
                return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, classes.oneMonthCompactTopContainer) }, { children: [renderConfirmationIcon(), _jsx("div", __assign({ className: cx(classes.compactTimeContainer, classes.compactTimeContainerOneMonth) }, { children: _jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer, classes.oneMonthCompactBottomContainer) }, { children: _jsx(Caption, __assign({ className: cx(classes.shiftNameBlock, classes.oneMonthShiftNameBlock), variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0) }), void 0), entryType === ShiftEntryTypes.isBlocked && (_jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.oneMonthBlockedIcon }, void 0))] }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, (_a = {},
                            _a[classes.paddingRight] = isDifferentFromTemplate,
                            _a)) }, { children: entryType === ShiftEntryTypes.isBlocked
                            ? _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.shiftIcon }, void 0)
                            : renderShiftIcon() }), void 0), _jsx("div", __assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer) }, { children: _jsx(Caption, __assign({ className: classes.shiftNameBlock, size: 12, weight: 500 }, { children: getAbbreviatedName(title) }), void 0) }), void 0)] }, void 0));
        };
        return (_jsxs("div", __assign({}, rest, { "data-testid": testId, className: cx(classes.container, (_a = {},
                _a[classes.isHover] = !cantRenderOverlay() && showHoverActions,
                _a[classes.isBlocked] = entryType === ShiftEntryTypes.isBlocked,
                _a["" + String(className)] = !!className,
                _a[classes.flash] = hasFlash,
                _a[classes.selected] = selected,
                _a[classes.isFlashMode] = isFlashMode,
                _a)) }, { children: [renderConfirmationIcon(), renderIsDifferentFromTemplate, !isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderOverlay(), renderContent()] }), void 0));
    };
    var renderOverlay = function () {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekOverlay();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksOverlay();
            case rangePickerVariantEnum['1_MONTH']:
                return renderMonthOverlay();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    var renderShiftIcon = function () {
        switch (icon) {
            case shiftIconEnum.BRIEFCASE:
                return _jsx(Briefcase, { "aria-label": 'briefcase-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.CAR:
                return _jsx(Car, { "aria-label": 'car-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.HEADPHONES:
                return _jsx(Headphones, { "aria-label": 'headphones-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.HOME:
                return _jsx(Home, { "aria-label": 'home-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.MOON:
                return _jsx(Moon, { "aria-label": 'moon-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.PHONE_ALT:
                return _jsx(PhoneAlt, { "aria-label": 'phone-alt-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.STAR:
                return _jsx(Star, { "aria-label": 'star-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.SUN:
                return _jsx(Sun, { "aria-label": 'sun-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.SUN_HAZE:
                return _jsx(SunHaze, { "aria-label": 'sun-haze-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.USERS:
                return _jsx(Users, { "aria-label": 'users-icon', className: classes.shiftIcon }, void 0);
            default:
                return _jsx(Briefcase, { "aria-label": 'briefcase-icon', className: classes.shiftIcon }, void 0);
        }
    };
    var renderContent = function () {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekContent();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksContent();
            case rangePickerVariantEnum['1_MONTH']:
                return renderOneMonthContent();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    if (entryType === ShiftEntryTypes.isBlocked) {
        return (_jsx(Tooltip, __assign({ title: blockedTitle || '' }, { children: renderContent() }), void 0));
    }
    return renderContent();
};
