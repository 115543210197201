const GLOBAL_PREFIX = 'jobdone-global';

export interface GlobalLocalStorageKeyMap {
  'lastSession': string; // value: <organizationId>.<organizationUserId>
  // Lucky: 2025-01-22, TODO: add alreadyUsedTheWebAppInMobileDevice, shouldAutoSelectOrganization, metricOverviewCheckBoxState (in org store??)
}

type GlobalLocalStorageKey = keyof GlobalLocalStorageKeyMap;

export function getGlobalLocalStorage<K extends GlobalLocalStorageKey>(
  key: K,
): GlobalLocalStorageKeyMap[K] | null {
  if (typeof window === 'undefined') return null;

  const fullKey = `${GLOBAL_PREFIX}.${key}`;
  const value = localStorage.getItem(fullKey);

  try {
    return value ? JSON.parse(value) : null;
  } catch {
    console.error(`Error parsing global storage value for key: ${key}`);
    return null;
  }
}

export function setGlobalLocalStorage<K extends GlobalLocalStorageKey>(
  key: K,
  value: GlobalLocalStorageKeyMap[K],
): void {
  if (typeof window === 'undefined') return;

  const fullKey = `${GLOBAL_PREFIX}.${key}`;
  try {
    localStorage.setItem(fullKey, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting global storage value for key: ${key}`, error);
  }
}

export function removeGlobalLocalStorage(key: GlobalLocalStorageKey): void {
  if (typeof window === 'undefined') return;

  const fullKey = `${GLOBAL_PREFIX}.${key}`;
  localStorage.removeItem(fullKey);
}

export function clearGlobalLocalStorage(): void {
  if (typeof window === 'undefined') return;

  const keysToRemove: string[] = [];

  for (let i = 0; i < localStorage.length; i += 1) {
    const key = localStorage.key(i);
    if (key?.startsWith(GLOBAL_PREFIX)) {
      keysToRemove.push(key);
    }
  }

  keysToRemove.forEach((key) => localStorage.removeItem(key));
}
