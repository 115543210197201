var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Body, Button, Caption, Icons, } from '../..';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        padding: '8px 8px 8px 16px',
        backgroundColor: theme.palette.common.white,
        width: '100%',
        borderRadius: 12,
        border: "2px solid " + theme.palette.grey[100],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16,
        transition: '0.2s',
        '&:hover': {
            backgroundColor: '#ECF6FF',
        },
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
    },
    size: {
        weight: 400,
        fontSize: 13,
        lineHeight: '16px',
        color: theme.palette.grey[500],
    },
    fileIcon: {
        color: theme.palette.primary.main,
    },
    downloadIcon: {
        color: theme.palette.grey[500],
    },
}); });
export var File = function (_a) {
    var style = _a.style, className = _a.className, name = _a.name, size = _a.size, url = _a.url, _b = _a.type, type = _b === void 0 ? 'download' : _b, handleDeleteFile = _a.handleDeleteFile, rest = __rest(_a, ["style", "className", "name", "size", "url", "type", "handleDeleteFile"]);
    var classes = useStyles();
    return (_jsxs("div", __assign({ style: style, className: cx(classes.root, className) }, rest, { children: [_jsxs("div", __assign({ className: classes.container }, { children: [_jsx(Icons.Files, { className: classes.fileIcon }, void 0), _jsxs("div", { children: [_jsx(Body, __assign({ weight: 700 }, { children: name }), void 0), _jsx(Caption, __assign({ className: classes.size }, { children: size }), void 0)] }, void 0)] }), void 0), type === 'download' && (_jsx(Button, { variant: 'fixedIcon', onClick: function (e) { return e.stopPropagation(); }, icon: (_jsx("a", __assign({ href: url, download: true, target: '_blank', rel: 'noreferrer' }, { children: _jsx(Icons.Download, { className: classes.downloadIcon }, void 0) }), void 0)), color: 'ghost' }, void 0)), type === 'delete' && (_jsx(Button, { variant: 'fixedIcon', icon: _jsx(Icons.Trash, { className: classes.downloadIcon }, void 0), color: 'ghost', onClick: handleDeleteFile }, void 0))] }), void 0));
};
