var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from 'classnames';
import { eventTypeIconEnum, eventTypeColorCodeEnum } from '@jobdone/jobdone-shared-library';
import useStyles from './EventEntry.style';
import { Caption, Text } from '../../../Typography';
import { EditAlt, MoreHorizontal, Trash, Event, Holiday, BDay, DogBalloon, HandshakeSolid, TrophySolid, MartiniGlassCitrusSolid, PeopleGroupSolid, PersonChalkboardSolid, Presentation, ArrowUpRight, } from '../../../icons';
import { getAbbreviatedName, useDropdownMenu } from '../../../../utils/helpers';
import { EntryMenuItems, eventMenuItems } from '../..';
import { rangePickerVariantEnum } from '../../../../utils';
import { Tooltip } from '../../../Tooltip';
export var EventEntry = function (_a) {
    var _b;
    var _c = _a.title, title = _c === void 0 ? 'Event 1' : _c, _d = _a.time, time = _d === void 0 ? 'All Day' : _d, _e = _a.color, color = _e === void 0 ? eventTypeColorCodeEnum.GREEN : _e, className = _a.className, _f = _a.icon, icon = _f === void 0 ? eventTypeIconEnum.EVENT : _f, onEditClick = _a.onEditClick, onDeleteClick = _a.onDeleteClick, menuItems = _a.menuItems, hasFlash = _a.hasFlash, _g = _a.showHoverActions, showHoverActions = _g === void 0 ? true : _g, selected = _a.selected, _h = _a.isFlashMode, isFlashMode = _h === void 0 ? false : _h, _j = _a.labels, labels = _j === void 0 ? {
        deleteLabel: 'Delete',
    } : _j, _k = _a.view, view = _k === void 0 ? rangePickerVariantEnum['1_WEEK'] : _k, _l = _a.isCompact, isCompact = _l === void 0 ? false : _l, entryType = _a.entryType, blockedTitle = _a.blockedTitle, rest = __rest(_a, ["title", "time", "color", "className", "icon", "onEditClick", "onDeleteClick", "menuItems", "hasFlash", "showHoverActions", "selected", "isFlashMode", "labels", "view", "isCompact", "entryType", "blockedTitle"]);
    var isBlocked = entryType === 'isBlocked';
    var classes = useStyles({
        color: color,
        hasFlash: hasFlash,
        selected: selected,
        isFlashMode: isFlashMode,
        isBlocked: isBlocked,
    });
    var _m = useDropdownMenu(), handleCloseMore = _m.handleClose, anchorEl = _m.anchorEl, handleClickMore = _m.handleClick;
    var _o = __read(time.split(' - '), 2), eventFromTime = _o[0], eventToTime = _o[1];
    var eventFromTimeRawHour = eventFromTime.split(':')[0];
    var eventFromTimeHour = eventFromTimeRawHour[0] === '0' ? eventFromTimeRawHour[1] : eventFromTimeRawHour;
    var eventFromTimeMinute = eventFromTime === null || eventFromTime === void 0 ? void 0 : eventFromTime.split(':')[1];
    var eventToTimeRawHour = (_b = eventToTime === null || eventToTime === void 0 ? void 0 : eventToTime.split(':')) === null || _b === void 0 ? void 0 : _b[0];
    var eventToTimeHour = (eventToTimeRawHour === null || eventToTimeRawHour === void 0 ? void 0 : eventToTimeRawHour[0]) === '0' ? eventToTimeRawHour[1] : eventToTimeRawHour;
    var eventToTimeMinute = eventToTime === null || eventToTime === void 0 ? void 0 : eventToTime.split(':')[1];
    var combineMenuItems = function () { return (menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(function (menuItem) { return (__assign(__assign({}, eventMenuItems.find(function (_a) {
        var key = _a.key;
        return key === menuItem.key;
    })), menuItem)); })) || []; };
    var cantRenderOverlay = !showHoverActions;
    var renderOneWeekOverlay = function () {
        var _a, _b;
        if (cantRenderOverlay)
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsxs("div", __assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneWeekOverlay, (_a = {},
                        _a[classes.compactViewOverlayButton] = isCompact,
                        _a)), onClick: onDeleteClick }, { children: [_jsx(Trash, { className: classes.trashIcon }, void 0), _jsx(Text, __assign({ className: classes.deleteLabel }, { children: labels.deleteLabel }), void 0)] }), void 0) }, void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneWeekOverlay, (_b = {},
                        _b[classes.compactViewOverlayButton] = isCompact,
                        _b)) }, { children: [_jsx("button", __assign({ onClick: onEditClick, className: classes.overlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", __assign({ onClick: handleClickMore, className: classes.overlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    var renderTwoWeeksOverlay = function () {
        if (cantRenderOverlay)
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsx("div", __assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.twoWeeksOverlay), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0) }, void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.twoWeeksOverlay) }, { children: [_jsx("button", __assign({ onClick: onEditClick, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", __assign({ onClick: handleClickMore, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    var renderOneMonthOverlay = function () {
        if (cantRenderOverlay)
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsx("div", __assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneMonthOverlay), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0) }, void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneMonthOverlay) }, { children: [_jsx("button", __assign({ onClick: onEditClick, className: classes.oneMonthOverlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", __assign({ onClick: handleClickMore, className: classes.oneMonthOverlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    var renderEventIcon = function () {
        switch (icon) {
            case eventTypeIconEnum.EVENT:
                return _jsx(Event, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.HOLIDAY:
                return _jsx(Holiday, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.BIRTHDAY:
                return _jsx(BDay, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.DOG_BALLOON:
                return _jsx(DogBalloon, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.HANDSHAKE:
                return _jsx(HandshakeSolid, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.TROPHY:
                return _jsx(TrophySolid, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.MARTINI_GLASS_CITRUS:
                return _jsx(MartiniGlassCitrusSolid, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.PEOPLE_GROUP:
                return _jsx(PeopleGroupSolid, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.PERSON_CHALKBOARD:
                return _jsx(PersonChalkboardSolid, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.PRESENTATION:
                return _jsx(Presentation, { className: classes.eventIcon }, void 0);
            default:
                return null;
        }
    };
    var renderOneWeekContent = function () {
        var _a;
        var renderContent = function () {
            if (isCompact) {
                return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer, classes.weekCompactView) }, { children: [_jsxs("div", __assign({ className: classes.compactViewIconAndTime }, { children: [isBlocked
                                        ? _jsx(ArrowUpRight, { className: classes.compactViewBlockedIcon }, void 0)
                                        : renderEventIcon(), _jsx("div", __assign({ className: classes.compactTimeContainer }, { children: !eventToTime
                                            ? (_jsx(Caption, __assign({ variant: 'regular', className: classes.compactViewEventName }, { children: getAbbreviatedName(eventFromTime) }), void 0))
                                            : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: eventFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: eventFromTimeMinute }), void 0)] }), void 0), "-", _jsxs("div", __assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: eventToTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: eventToTimeMinute }), void 0)] }), void 0)] }, void 0)) }), void 0)] }), void 0), _jsx(Caption, __assign({ className: cx(classes.eventNameBlock, classes.compactViewEventName), weight: 500 }, { children: title }), void 0)] }), void 0) }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer) }, { children: [renderEventIcon(), _jsx(Caption, __assign({ variant: 'regular' }, { children: time }), void 0)] }), void 0), _jsxs("div", __assign({ className: cx(classes.bottomContainer, classes.oneWeekBottomContainer) }, { children: [_jsx(Caption, __assign({ className: classes.eventNameBlock, variant: 'regular' }, { children: title }), void 0), isBlocked && _jsx(ArrowUpRight, { className: classes.eventIcon }, void 0)] }), void 0)] }, void 0));
        };
        return (_jsx("div", __assign({}, rest, { className: cx(classes.container, classes.oneWeekContainer, (_a = {},
                _a[classes.isHover] = !cantRenderOverlay,
                _a[classes.isBlocked] = isBlocked,
                _a[classes.flash] = hasFlash,
                _a[classes.selected] = selected,
                _a[classes.isFlashMode] = isFlashMode,
                _a["" + String(className)] = !!className,
                _a)) }, { children: selected
                ? (_jsxs("div", __assign({ className: classes.selectedContainer }, { children: [renderContent(), renderOverlay()] }), void 0))
                : (_jsxs(_Fragment, { children: [renderContent(), renderOverlay()] }, void 0)) }), void 0));
    };
    var renderTwoWeeksContent = function () {
        var _a;
        return (_jsxs("div", __assign({}, rest, { className: cx(classes.container, classes.twoWeeksContainer, (_a = {},
                _a[classes.isHover] = !cantRenderOverlay,
                _a[classes.isBlocked] = isBlocked,
                _a[classes.flash] = hasFlash,
                _a[classes.selected] = selected,
                _a[classes.isFlashMode] = isFlashMode,
                _a["" + String(className)] = !!className,
                _a)) }, { children: [renderOverlay(), _jsxs("div", __assign({ className: cx(classes.topContainer, classes.twoWeeksTopContainer) }, { children: [isBlocked
                            ? _jsx(ArrowUpRight, { className: classes.eventIcon }, void 0)
                            : renderEventIcon(), _jsx(Caption, __assign({ className: cx(classes.eventNameBlock, classes.twoWeekViewEventNameBlock), variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0)] }), void 0), _jsx("div", __assign({ className: cx(classes.bottomContainer, classes.twoWeeksBottomContainer) }, { children: _jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: classes.twoWeeksTimeContainer }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: eventFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.twoWeeksTimeMinute, size: 8, variant: 'regular' }, { children: eventFromTimeMinute }), void 0)] }), void 0), eventToTimeMinute && (_jsxs(_Fragment, { children: ["-", _jsxs("div", __assign({ className: classes.twoWeeksTimeContainer }, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: eventToTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.twoWeeksTimeMinute, size: 8, variant: 'regular' }, { children: eventToTimeMinute }), void 0)] }), void 0)] }, void 0))] }, void 0) }), void 0)] }), void 0));
    };
    var renderOneMonthContent = function () {
        var _a;
        var renderContent = function () {
            if (isCompact) {
                return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, classes.oneMonthCompactTopContainer) }, { children: _jsx("div", __assign({ className: cx(classes.compactTimeContainer, classes.compactTimeContainerOneMonth) }, { children: _jsx("div", __assign({ className: classes.compactTimeEntry }, { children: !eventFromTimeMinute
                                        ? (_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: getAbbreviatedName(eventFromTimeHour) }), void 0))
                                        : (_jsxs(_Fragment, { children: [_jsx(Caption, __assign({ size: 12, variant: 'regular' }, { children: eventFromTimeHour }), void 0), _jsx(Caption, __assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: eventFromTimeMinute }), void 0)] }, void 0)) }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer, classes.oneMonthCompactBottomContainer) }, { children: _jsx(Caption, __assign({ className: cx(classes.eventNameBlock, classes.oneMonthEventNameBlock), variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0) }), void 0), isBlocked && (_jsx(ArrowUpRight, { className: classes.oneMonthBlockedIcon }, void 0))] }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer) }, { children: isBlocked
                            ? _jsx(ArrowUpRight, { className: classes.eventIcon }, void 0)
                            : renderEventIcon() }), void 0), _jsx("div", __assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer) }, { children: _jsx(Caption, __assign({ className: classes.eventNameBlock, variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0) }), void 0)] }, void 0));
        };
        return (_jsxs("div", __assign({}, rest, { className: cx(classes.container, classes.oneMonthContainer, (_a = {},
                _a[classes.isHover] = showHoverActions,
                _a[classes.isBlocked] = isBlocked,
                _a[classes.flash] = hasFlash,
                _a[classes.selected] = selected,
                _a[classes.isFlashMode] = isFlashMode,
                _a["" + String(className)] = !!className,
                _a)) }, { children: [renderOverlay(), renderContent()] }), void 0));
    };
    var renderOverlay = function () {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekOverlay();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksOverlay();
            case rangePickerVariantEnum['1_MONTH']:
                return renderOneMonthOverlay();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    var renderContent = function () {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekContent();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksContent();
            case rangePickerVariantEnum['1_MONTH']:
                return renderOneMonthContent();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    if (isBlocked && blockedTitle) {
        return (_jsx(Tooltip, __assign({ title: blockedTitle }, { children: renderContent() }), void 0));
    }
    return renderContent();
};
