var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
var TimeBalancesOutline = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    return (_jsx("span", __assign({ className: cx(useStyles().jobDoneIcon, className) }, rest, { style: {} }, { children: _jsxs("svg", __assign({ width: '24', height: '24', viewBox: '2 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M5 4C5 3.44772 5.44772 3 6 3H18C18.5523 3 19 3.44772 19 4C19 4.55228 18.5523 5 18 5H6C5.44772 5 5 4.55228 5 4Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M5 20C5 19.4477 5.44772 19 6 19H18C18.5523 19 19 19.4477 19 20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M8 3C8.55228 3 9 3.44772 9 4V8C9 8.27012 9.14006 8.58634 9.41437 8.81078L12 10.75L14.5856 8.81077C14.8599 8.58634 15 8.27012 15 8V4C15 3.44772 15.4477 3 16 3C16.5523 3 17 3.44772 17 4V8C17 8.92651 16.5436 9.80577 15.8247 10.3809C15.8166 10.3874 15.8083 10.3938 15.8 10.4L12.6 12.8C12.2444 13.0667 11.7556 13.0667 11.4 12.8L8.2 10.4C8.19167 10.3938 8.18344 10.3874 8.17531 10.3809C7.45643 9.80577 7 8.92651 7 8V4C7 3.44772 7.44772 3 8 3Z', fill: 'currentColor' }, void 0), _jsx("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M11.4 11.2C11.7556 10.9333 12.2444 10.9333 12.6 11.2L15.8 13.6C15.8083 13.6062 15.8166 13.6126 15.8247 13.6191C16.5436 14.1942 17 15.0735 17 16V20C17 20.5523 16.5523 21 16 21C15.4477 21 15 20.5523 15 20V16C15 15.7299 14.8599 15.4137 14.5856 15.1892L12 13.25L9.41437 15.1892C9.14006 15.4137 9 15.7299 9 16V20C9 20.5523 8.55228 21 8 21C7.44772 21 7 20.5523 7 20V16C7 15.0735 7.45643 14.1942 8.17531 13.6191C8.18344 13.6126 8.19167 13.6062 8.2 13.6L11.4 11.2Z', fill: 'currentColor' }, void 0)] }), void 0) }), void 0));
};
export default TimeBalancesOutline;
